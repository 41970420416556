[data-embedded="true"] {
  body {
    background: none;
  }
  .form {
    min-height: 0;
  }
  .background {
    display: none;
  }
  .logo {
    display: none;
  }
  .seeker-form {
    margin: 0 auto;
  }
}
