@font-face {
  font-family: "Noir Pro";
  src: local(""), url("../../font/NoirPro-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noir Pro";
  src: local(""), url("../../font/NoirPro-Italic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Noir Pro";
  src: local(""), url("../../font/NoirPro-Medium.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noir Pro";
  src: local(""), url("../../font/NoirPro-Bold.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

:root {
  --font-sans: "Noir Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
}
