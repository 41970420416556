.button {
  min-width: 120px;

  svg {
    margin-left: 10px;
    width: 15px;
    height: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .button {
    min-width: 200px;
  }
}
