.page {
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding: var(--space-lg);

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: var(--space-md);

  .logo {
    position: relative;
    z-index: 1;
    margin-top: var(--space-sm);

    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
      max-width: 16rem;
      max-height: 4rem;
      margin: 0 auto;
    }
  }

  .page-card {
    background: #fff;
    position: relative;
    z-index: 1;
    width: 45rem;
    min-height: 20rem;
    border-radius: 0.75rem;
    border: var(--border-width) solid rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: var(--space-md) var(--space-md);
    animation: 0.5s 0.2s ease forwards slideInForm;
    transition: 0.2s all;
    opacity: 0;
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
  }
}

@media only screen and (max-width: 768px) {
  .page {
    padding: var(--space-lg) var(--space-sm);

    .logo {
      width: 180px;
      min-height: unset;
    }

    .page-card {
      width: 100%;
    }
  }
}

@keyframes slideInForm {
  from {
    opacity: 0;
    transform: translateY(1rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
