.survey-progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 7.5px;
  border-radius: 10px;
  background-color: transparent;
  overflow: hidden;

  .progress {
    background-color: var(--color-primary);
    height: 100%;
    transition: 0.2s ease-in-out;
  }
}

@media only screen and (max-width: 768px) {
  .survey-progress-bar {
    width: 80%;
  }
}
