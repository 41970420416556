:root {
  //To be overriden
  --color-primary: #333333;
  --color-accent-2: #dddddd;

  --color-primary-intense: var(--color-primary-4);
  --color-link-default: var(--color-accent-3);
  --color-link-hover: var(--color-accent-4);
  --border-width: 1px;
  --color-ui: #b0bec5;
  --color-ui-muted: #cfd8dc;
  --color-ui-intense: #546e7a;

  --color-danger-muted: #f44336;
  --color-warning-muted: #f9a825;
  --color-success-muted: #4caf50;
}
