.survey-answers {
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);

  .survey-answer-row {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }

  .previous-response-text {
    opacity: 0.5;
  }
}

@media only screen and (max-width: 768px) {
  .survey-answers {
    flex-direction: column;
    row-gap: var(--space-sm);
  }
}
