.survey-result {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-sm);

  .survey-facebook-link, .survey-course-link {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 768px) {
  .survey-result .survey-result-text {
    font-size: 1.25rem;
    width: 90%;
  }
}
