.uploading-media-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--space-md);

  .remove-upload {
    color: darkred;
    font-weight: 500;
    margin: 0;
    cursor: pointer;

    &:hover {
      filter: brightness(0.5);
    }
  }
}

.uploading-media {
  width: 100%;

  .media-header-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .media-name {
      margin: 0;
      word-wrap: break-word;
    }
  }

  .upload-error {
    color: darkred;
    font-size: 0.85rem;
    margin: 0;
  }

  .upload-success {
    color: green;
    font-size: 0.85rem;
    margin: 0;
  }

  .progress-bar {
    margin-top: var(--space-sm);
    width: 100%;
    height: 10px;
    border-radius: 5px;
    overflow: hidden;
    background-color: #ddd;

    .progress {
      height: 100%;
      min-width: 5px;
      background-color: var(--color-primary);
      transition: 0.2s all linear;
    }
  }
}
