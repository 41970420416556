.testimony-upload {
  width: 100%;

  .upload-title {
    margin-bottom: var(--space-sm);
    p {
      margin: 0;
      font-size: 0.8rem;
    }

    .remove-upload {
      margin: 0;
      color: darkred;
      font-weight: 500;
      font-size: 0.8rem;
      cursor: pointer;

      &:hover {
        filter: brightness(0.5);
      }
    }
  }

  .profile-photo-container {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;

    .profile-photo {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
