$animation-width: 25vw;
$animation-timing: 100ms;

.survey-node {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  row-gap: var(--space-sm);

  .survey-node-text {
    font-size: 0.9rem;

    // &.enter {
    //   animation: enter $animation-timing ease-in;
    // }

    // &.leave {
    //   animation: leave $animation-timing ease-out;
    // }

    // &.reversed {
    //   animation-direction: reverse;
    // }

    &.hidden {
      opacity: 0;
    }
  }

  .control-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .control-button {
      display: flex;
      align-items: center;
      column-gap: 10px;
      cursor: pointer;

      &:hover {
        text-decoration-line: underline;
      }
      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .survey-node .survey-node-text {
    font-size: 1.25rem;
    width: 100%;
  }
}

@keyframes enter {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes leave {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
