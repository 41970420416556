.survey-testimony {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .media-upload-row {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--space-md);
    margin-bottom: var(--space-md);

    button {
      width: fit-content;
    }
  }

  textarea {
    margin-top: 10px;
    width: 100%;
    height: 300px;
    resize: none;
    padding: 10px 15px;
  }

  .remaining-label {
    width: 100%;
    text-align: right;
  }
}
