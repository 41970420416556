body {
  background-image: linear-gradient(to bottom, var(--color-accent-1), white);
}
.background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  --swoop-size: clamp(30rem, 250vw, 100rem);
}
svg.swoop {
  position: fixed;
  bottom: calc(var(--swoop-size) * -0.65);
  left: calc(var(--swoop-size) * -0.25);
  width: calc(var(--swoop-size) * 1);
  height: calc(var(--swoop-size) * 1);
  max-width: none;
  z-index: 0;
  path {
    transition: fill 150ms;
  }
}

.swoop-primary {
  animation: 2s cubic-bezier(0.5, 0, 0, 1) slideInPrimary;
  path {
    fill: var(--color-primary);
  }
}
.swoop-accent {
  animation: 2s cubic-bezier(0.5, 0, 0, 1) slideInAccent;
  path {
    fill: var(--color-accent-2);
  }
}
.grain {
  position: fixed;
  width: 100%;
  height: 200%;
  top: 0;
  left: 0;
  background-image: url("./noise.png");
  background-repeat: repeat;
  mix-blend-mode: hard-light;
  opacity: 0.7;
}

@keyframes slideInPrimary {
  from {
    transform: translate3d(20%, 20%, 0) rotate(-20deg);
  }

  to {
    transform: translate3d(0, 0, 0) rotate(0);
  }
}

@keyframes slideInAccent {
  from {
    transform: translate3d(-10%, 20%, 0) rotate(-40deg);
  }

  to {
    transform: translate3d(0, 0, 0) rotate(0);
  }
}
