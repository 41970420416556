.drag-drop-input {
  width: 100%;
  padding: var(--space-md);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed var(--color-primary);

  .dropzone-content {
    text-align: center;
    .dropzone-content-link {
      text-decoration-line: underline;
      color: var(--color-accent);
      cursor: pointer;
      transition: 0.2s all;

      &:hover {
        filter: brightness(0.9);
      }
    }
  }
}

.upload-error {
  color: darkred;
  font-size: 0.9rem;
}
