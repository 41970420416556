.survey-review {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-md);

  .survey-review-text {
    color: var(--text-question);
    font-size: 1.5rem;
    text-align: center;
    width: 80%;
  }

  .answers-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: var(--color-accent-1);
    padding: 0 var(--space-sm);

    .survey-review-answer {
      padding: var(--space-sm);
      border-bottom: 1px solid #fff;
      p {
        margin: 0;
        font-size: 0.9rem;
      }

      .answer {
        font-size: 1rem;
        font-weight: bold;
        text-transform: capitalize;

        &.testimony {
          text-transform: none;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .review-button-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
}

@media only screen and (max-width: 768px) {
  .survey-review {
    .survey-review-text {
      font-size: 1.25rem;
    }

    .review-button-row {
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
  }
}
